<script lang="ts" setup>
import { ref, computed } from "vue";
const props = defineProps({
  registerId: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const breadCrumbName = ref(undefined);

const breadcrumbs = computed(() => {
  let list = [{ default: "EU-Registers" }];
  if (props.addModus) {
    list.push({
      text: "toevoegen",
      disabled: true,
    });
  } else {
    list.push({
      text: props.breadCrumbName,
      to: { name: "EU-Register", params: { userId: props.registerId } },
    });
  }
  return list;
});

function updateBreadCrumbName(value) {
  breadCrumbName.value = value;
}
</script>

<template>
  <v-container fluid pa-0 class="app-register">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <header class="app-welcome-message">
        <h1 v-if="registerId">Meter: {{ breadCrumbName }}</h1>
        <h1 v-else-if="addModus">Meter toevoegen</h1>
        <h1 v-else>Meter</h1>
      </header>

      <router-view @update-register-reference="updateBreadCrumbName"></router-view>
    </v-container>
  </v-container>
</template>
